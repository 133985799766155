* {
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(60, 60, 60);
  text-align: center;
  align-content: center;
  display:flex; 
  flex-direction:column; 
  justify-content:center;
  min-height:100vh;
  --color_input: black;
  --color_textarea: white;
  --color_button: green;
  --color_write: rgb(60, 60, 60);
}

h1 {
  color: white;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

input, button {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

input[type="text"] {
  color: var(--color_textarea);
  font-size: 1rem;
  height: 2rem;
  width: 15rem;
  padding-left: .5rem;
  margin: 1rem auto;
  cursor: text;
  box-shadow: none;
  background-color: var(--color_write);
  border: 1.5px solid var(--color_input);
  border-top: 0 none;
  border-left: 0 none;
  border-right: 0 none;
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
input::placeholder
{
  color: var(--color_textarea);
  opacity: 1;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 15px;
}

/* Internet Explorer 10-11 */
input:-ms-input-placeholder
{
  color: var(--color_textarea);
  opacity: 1;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 15px;
}

/* Microsoft Edge */
input::-ms-input-placeholder
{
  color: var(--color_textarea);
  opacity: 1;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 15px;
}

/* Change the white to any color ;) */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type="submit"], button
{
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: none;
  border-radius: .5rem;
  cursor: pointer;
  height: 2rem;
  width: 15rem;
  background-color: var(--color_button);
}

#success, #url, #arrow {
  display: none;
  color: green;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

a:link, a:active, a:hover, a:visited, a:focus {
  color: green;
  text-decoration: none;
  display: flex;
}

a:hover {
  text-decoration: underline;
}

#failure {
  display: none;
  color: red;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}
